import { DataTableRowDef } from './DataTableRowDef'

class DataTableDef {
  public readonly RowDef: DataTableRowDef;

  constructor (rowDef: DataTableRowDef) {
    this.RowDef = rowDef
  }
}

export { DataTableDef }
