
import { Component, Vue } from 'vue-property-decorator'
import { DataTableDef, DataTableRowDef, DataTableColDef } from '../../app_code/DataTable'
import { DropdownListItem } from 'tradingmate-modules/src/forms/DropdownListItem'

@Component
export default class UIDemo extends Vue {
  // private dtData: object | null = null;

  private modal1Active = false;
  private modal2Active = false;
  private modal3Active = false;

  private ddlItems = [
    new DropdownListItem('correct', 'Correct', { type: 'component', value: 'correct' }),
    new DropdownListItem('horse', 'Horse', { type: 'component', value: 'horse' }),
    new DropdownListItem('battery', 'Battery', { type: 'component', value: 'battery' }),
    new DropdownListItem('staple', 'Staple', { type: 'component', value: 'staple' })
  ]

  private rbItems = [
    new DropdownListItem('correct', 'Correct', { type: 'component', value: 'correct' }),
    new DropdownListItem('horse', 'Horse', { type: 'component', value: 'horse' }),
    new DropdownListItem('battery', 'Battery', { type: 'component', value: 'battery' }),
    new DropdownListItem('staple', 'Staple', { type: 'component', value: 'staple' })
  ]

  private dtDef: DataTableDef = new DataTableDef(new DataTableRowDef(ep => ep.id, [
    new DataTableColDef('Season', row => row.season),
    new DataTableColDef('Episode', row => row.number),
    new DataTableColDef('Name', row => row.name),
    new DataTableColDef('Air Date', row => row.airdate),
    new DataTableColDef('Type', row => row.type)
  ]))

  mounted (): void {
    // fetch('/data/demo/shows.json').then(res => {
    //   res.json().then(data => {
    //     this.dtData = data
    //   })
    // })
  }

  closeModal2 (): void {
    this.$router.push('/account/login')
  }
}
