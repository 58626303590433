/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataTableColDef } from './DataTableColDef'

class DataTableRowDef {
  public readonly KeyAccessor: (row: any) => string;
  public readonly Cols: DataTableColDef[];
  public MapForSearch?: (row: any) => string[];

  constructor (keyAccessor: (row: any) => string, cols: DataTableColDef[]) {
    this.KeyAccessor = keyAccessor
    this.Cols = cols
  }

  SetSearchMapper (accessor: (row: any) => string[]): DataTableRowDef {
    this.MapForSearch = accessor

    return this
  }
}

export { DataTableRowDef }
