/* eslint-disable @typescript-eslint/ban-types */
class DropdownListItem {
    public readonly Key: string;
    public readonly Label: string;
    public readonly Value?: object | number | string;

    constructor(key: string, label: string, value?: object | number) {
        this.Key = key
        this.Label = label
        this.Value = value
    }
}

export {DropdownListItem}
